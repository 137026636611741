import React, {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './App.css';
import { Outlet, useNavigation } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Footer from './components/Footer';
import Button from 'react-bootstrap/Button';
import { HelmetProvider } from 'react-helmet-async';
import ScrollToTop from "./helpers/ScrollToTop";

export default function App() {
  const helmetContext = {};
  const navigation = useNavigation();
  const [expanded, setExpanded] = useState(false);
 
  
   return (
    <HelmetProvider context={helmetContext}>
    <ScrollToTop />
    <div className="App">
      <Navbar bg="light" expand="lg" expanded={expanded}>
        <Container>
          <LinkContainer to={`/`}><Navbar.Brand><img src={process.env.PUBLIC_URL + 'AltFireLogo.png'} alt="Alt Fire" /></Navbar.Brand></LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav>
              <LinkContainer to={`/`} onClick={() => setTimeout(() => {setExpanded(false)}, 100)}><Nav.Link>Home</Nav.Link></LinkContainer>
              <LinkContainer to={`services`} onClick={() => setTimeout(() => {setExpanded(false)}, 100)}><Nav.Link>Services</Nav.Link></LinkContainer>
              <LinkContainer to={`team`} onClick={() => setTimeout(() => {setExpanded(false)}, 100)}><Nav.Link>Team</Nav.Link></LinkContainer>
              <LinkContainer to={`careers`} onClick={() => setTimeout(() => {setExpanded(false)}, 100)}><Nav.Link>Careers</Nav.Link></LinkContainer>
              <LinkContainer to={`contact-us`} onClick={() => setTimeout(() => {setExpanded(false)}, 100)}><Button variant="primary">Get in touch</Button></LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <main className={navigation.state === "loading" ? "loading" : ""}>
      <Outlet />
      </main>
      
      <Footer />

    </div>
    </HelmetProvider>
  );
}
