import Header from "../components/Header";
import Button from 'react-bootstrap/Button';
import { ServicesSummaryList } from "./Services";
import { LinkContainer } from 'react-router-bootstrap';
import { PeopleSummaryList } from "./Team";
import { ContactUsSummary } from "./ContactUs";
import SEO from '../components/SEO';

export default function Home() {

    const contactButton = <LinkContainer to="contact-us"><Button variant="primary">Get in touch</Button></LinkContainer>
    const servicesButton = <LinkContainer to="services"><Button variant="outline-primary">Our services</Button></LinkContainer>

    return (
        <div>
            <SEO 
                title="Alt Fire - Fire Engineering Consultancy" 
                description="Independent fire engineering consultancy led by chartered engineers providing fire safety, fire engineering and building regulation compliance services."
                name="Alt Fire - Fire Engineering Consultancy"
                type="website"
            />
            <Header 
                title="We're an independent fire engineering consultancy led by Chartered Engineers" 
                subheading="We have significant experience in a wide range of building types in the UK and internationally." 
                section=""
                primaryCTA={contactButton}
                secondaryCTA={servicesButton}
                />
            <ServicesSummaryList />
            <PeopleSummaryList />
            <ContactUsSummary />

        </div>
    )
}