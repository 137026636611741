import Header from "../components/Header";
import SEO from "../components/SEO";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function AboutUs() {
    return (
        <div>
            <SEO
                title="Careers | Alt Fire" 
                description="Alt Fire is an independent fire engineering consultancy based in London and Manchester"
                name="Careers | Alt Fire"
                type="website"
            />
            <Header title="Work with us" subheading="" section="Careers" />
            <Container className="main-content">
                <Row>
                    <Col>
                        <p>We are currently recruiting to expand our team and are interested in talking to fire engineers at all levels.</p>
                        <p>Please contact us to discuss or send your CV to <a href="mailto:careers@altfire.co.uk">careers@altfire.co.uk</a> and we’ll be in touch.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}