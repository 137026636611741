import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider} from "react-router-dom";
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorPage from './error-page';
import Services from './routes/Services';
import Home from './routes/Home';
import Team from './routes/Team';
import Careers from './routes/Careers';
import ContactUs from './routes/ContactUs';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      { 
        index: true,
        element: <Home />
      },
      {
        path: "services",
        element: <Services />
      },
      {
        path: "team",
        element: <Team />
      },
      {
        path: "Careers",
        element: <Careers />
      },
      {
        path: "contact-us",
        element: <ContactUs />
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
