import { useRouteError } from "react-router-dom";
import React from 'react';
import Header from "./components/Header";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <Header title="404 - Page Not Found" subheading="" section="Error" />
      <Container className="main-content">
        <Row className="justify-content-start">
          <Col xs={12} xl={8}>
            <a href="/">Go to Alt Fire Home</a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}