import React from 'react';
import Header from "../components/Header";
import SEO from "../components/SEO";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { LinkContainer } from 'react-router-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';

const services = [
    {
        "name" : "Fire safety strategies",
        "description" : "<ul><li>Fire safety strategies</li><li>Part B compliance</li><li>Plan reviews and design advice</li><li>RIBA Stage 0 to RIBA Stage 7 fire safety advice</li><li>Business and operational continuity strategies</li></ul>"
    },
    {
        "name" : "Structural fire engineering",
        "description" : "<ul><li>Time equivalence analysis</li><li>Rationalisation of fire resistance periods</li><li>Finite element modelling</li></ul>"
    },
    {
        "name" : "Building regulations compliance advice",
        "description" : "<ul><li>Design review and advice</li><li>Part B consultation advice</li><li>Building Control liaison</li><li>Pre-purchase and post-purchase inspections</li></ul>"
    },
    {
        "name" : "Construction advice",
        "description" : "<ul><li>Construction phase fire risk assessments</li><li>HSG 168 compliance advice</li><li>CDM compliance</li><li>Phased and partial occupation strategies</li><li>Construction stage fire safety strategies</li></ul>"
    },
    {
        "name" : "Planning statements",
        "description" : "<ul><li>Planning Gateway One</li><li>The London Plan</li><li>Planning Gateway Two Pre-Consultation</li><li>HSE liaison</li></ul>"
    },
    {
        "name" : "External wall/EWS1 services",
        "description" : "<ul><li>PAS 9980 assessment</li><li>Design review</li><li>Remediation advice</li></ul>"
    },
    {
        "name" : "Modelling and Simulation",
        "description" : "<ul><li>Computational Fluid Dynamics (CFD) modelling</li><li>Evacuation modelling</li><li>Zone modelling</li><li>Heat transfer modelling</li><li>Cost benefit analyses</li><li>Empirical calculations</li></ul>"
    },
    {
        "name" : "Third Party Review",
        "description" : "<ul><li>Client-side assurance</li><li>Building Control reviews</li><li>Independent design reviews</li></ul>"
    }
];

const sectors = [
    {"name" : "Aviation and Airport Terminals"},
    {"name" : "Assembly Buildings"},
    {"name" : "Correctional and Remand"},
    {"name" : "Education"},
    {"name" : "Entertainment"},
    {"name" : "Fire Service Training Facilities"},
    {"name" : "Hospital and Healthcare"},
    {"name" : "Hotel and Leisure"},
    {"name" : "Offices"},
    {"name" : "Rail Infrastructure and Rolling Stock"},
    {"name" : "Residential"},
    {"name" : "Retail / Shopping Centres"},
    {"name" : "Stadia"},
    {"name" : "Warehousing and Industrial"}
];

export default function Services() {
    return (
        <div>
            <SEO
                title="Fire engineering services | Alt Fire" 
                description="We provide a range of fire engineering services across the build environment including fire safety design, structural fire engineering, regulation & compliance services, modelling and simulation."
                name="Fire engineering services | Alt Fire"
                type="website"
            />
            <Header title="Fire engineering services" subheading="" section="Services" />
            <Container className="main-content">
                <Row className="justify-content-start">
                    <Col xs={12} xl={8}>
                        <Row>
                            <Col>
                                <p>Alt Fire offers a range of fire engineering services across the built environment. Explore some of our typical services below. If your requirements are not met by the list below please get in touch to discuss.</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                        <Row className="justify-content-center">
                            <Col xs={12} lg={12}>
                                <Row className="my-4">
                                    <Col xs={12} sm={6}>
                                        { services.map((service, index) => (
                                            <Accordion defaultActiveKey="0" key={index} flush>
                                                {index % 2 === 0 ?
                                                <Accordion.Item  eventKey={index}>
                                                    <Accordion.Header>{service.name}</Accordion.Header>
                                                    <Accordion.Body dangerouslySetInnerHTML={ { __html: service.description }}></Accordion.Body>
                                                </Accordion.Item>
                                                : null }
                                            </Accordion>
                                        ))}
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        { services.map((service, index) => (
                                            <Accordion defaultActiveKey="0" flush key={index} >
                                                {index % 2 !== 0 ?
                                                <Accordion.Item eventKey={index}>
                                                    <Accordion.Header>{service.name}</Accordion.Header>
                                                    <Accordion.Body dangerouslySetInnerHTML={ { __html: service.description }}></Accordion.Body>
                                                </Accordion.Item>
                                                : null }
                                            </Accordion>
                                        ))}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h2 className="mt-5">Sectors</h2>
                                <p>We work across the build environment and have experience working in the following development types.</p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col xs={12} lg={12}>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <ListGroup variant="flush">
                                        {sectors.map(function (sector, index) {
                                            return (index % 2 === 0) ? <ListGroup.Item key={index}>{sector.name}</ListGroup.Item> :  null
                                        })}
                                        </ListGroup>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <ListGroup variant="flush">
                                        {sectors.map(function (sector, index) {
                                            return (index % 2 !== 0) ? <ListGroup.Item key={index}>{sector.name}</ListGroup.Item> :  null
                                        })}
                                        </ListGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <p className="text-center mt-5">
                                    <LinkContainer to="/contact-us"><Button variant="primary">Get in touch</Button></LinkContainer>
                                </p>    
                            </Col>
                        </Row>
                    
            </Container>
        </div>
    )
}

export function ServicesSummaryList() {
    return (
        <div className="summary-section">
            <Container>
                <Row className="justify-content-center mb-5">
                    <Col xs={12} md={8}>
                        <h2 className="text-center">Fire engineering services for your building projects</h2>
                        <p className="text-center">Alt Fire offers a range of fire engineering services across the built environment. Explore some of our typical services below. If your requirements are not met by the list below please get in touch to discuss.</p>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xs={12} lg={8}>
                        <Row>
                            <Col xs={12} sm={6}>
                                <ListGroup variant="flush">
                                {services.map(function (service, index) {
                                    return (index % 2 === 0) ? <ListGroup.Item key={index}>{service.name}</ListGroup.Item> :  null
                                })}
                                </ListGroup>
                            </Col>
                            <Col xs={12} sm={6}>
                                <ListGroup variant="flush">
                                {services.map(function (service, index) {
                                    return (index % 2 !== 0) ? <ListGroup.Item key={index}>{service.name}</ListGroup.Item> :  null
                                })}
                                </ListGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                    
                <Row>
                    <Col>
                        <p className="text-center mt-5"><LinkContainer to="services"><Button variant="primary">View our services</Button></LinkContainer></p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}