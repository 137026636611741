import React from 'react';
import Header from "../components/Header";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { LinkContainer } from 'react-router-bootstrap';
import SEO from "../components/SEO";

const people = [
    {
        "name" : "Andrew Willmott",
        "qualification" : "BEng (Hons) Grad Dip. (Fire Safety Engineering) CEng MIFireE",
        "email": "Andrew.Willmott@altfire.co.uk",
        "jobTitle" : "Director",
        "description": "<p>Andrew is an experienced Chartered Fire Engineer who has developed fire safety strategies and carried out fire engineering analysis on a wide range of projects including airport terminals, assembly, correctional, educational, hospital and health care, office, rail, residential, and retail developments.</p><p>Over the last 13 years he has worked on projects across the UK, Middle East, Asia, and Australia. Prior to his current role as a Director of Alt Fire, he has led teams in global fire engineering consultancies in the UK, worked in project management in the mining industry, and was an on-call operational fire fighter.</p>"
    },
    {
        "name" : "Matthew Eyre",
        "qualification" : "MEng (Hons) MEng (Fire Safety) MBA CEng MIFireE",
        "email": "Matthew.Eyre@altfire.co.uk",
        "jobTitle" : "Director",
        "description": "<p>Matthew is a Chartered Fire Engineer and a Member of the Institution of Fire Engineers. Based in Manchester, Matthew has worked on a range of projects from high rise residential buildings and high rise office buildings, to heritage cultural centres and smaller developments. He is experienced with performance-based fire engineering design, fire strategies, fire safety statements, fire safety audits and inspections, and fire risk assessments. He adopts a range of qualitative and quantitative assessment methodologies, including CFD and evacuation modelling, when required.</p><p>Matthew started his career in Australia and has worked on a range of British, Australian and international projects, including international projects in Singapore, Malaysia, Hong Kong, India, Papua New Guinea, and Qatar. He developed an international outlook while living in 6 countries and 11 cities, and has a proven track record of adapting to and thriving in new cultures.</p>"
    }
];
 

const Profile = ({person}) => {
  return (
    <div className="profile mt-5">
        <h3>{person.name}</h3>
        <p className="qualification">{person.qualification}</p> 
        <p className="job-title">{person.jobTitle}</p>
        <div dangerouslySetInnerHTML={{__html: person.description}}></div>
        <p><a href={`mailto:${person.email}`}>{person.email}</a></p>

    </div>
  )
}

const ProfileSummary = ({person}) => {
    return (
      <div className="profile">
          <h3>{person.name} <span className="job-title">{person.jobTitle}</span></h3>
          <p className="qualification">{person.qualification}</p>
      </div>
    )
  }

export default function Team() {
    return (
        <div>
            <SEO
                title="Meet the Team | Alt Fire" 
                description="Our directors are chartered fire engineers with decades of experience and a wealth of knowledge gained working on some of the largest construction projects in the UK and globally. We provide a range of fire engineering services across the build environment including fire safety design, structural fire engineering, regulation & compliance services, modelling and simulation."
                name="Meet the Team | Alt Fire"
                type="website"
            />
            <Header title="Meet the Alt Fire team" subheading="" section="Our Team" />
            <Container className="main-content">
                <Row className="justify-content-start">
                    <Col xs={12} xl={8}>
                        <Row>
                            <Col>
                                <p>Our directors are chartered fire engineers with decades of experience and a wealth of knowledge gained working on some of the largest construction projects in the UK and globally. Our team is led by:</p>
                            </Col>
                        </Row>
                        
                    </Col>
                </Row>
                <Row>
                    {people.map((person, index) => (
                        <Col xs={12} md={{ span: 5, offset: index === 1 ? 1 : 0 }} key={index}>
                            <Profile person={person} />
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    )
}

export function PeopleSummaryList() {
    return (
        <div className="bg-light summary-section">
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} md={8}>
                        <h2 className="text-center">Who you'll work with</h2>
                        <p className="text-center">Our directors are chartered fire engineers with decades of experience and a wealth of knowledge gained working on some of the largest construction projects in the UK and globally. </p>
                    </Col>
                </Row>
                {/* <Row className="justify-content-center">
                    <Col xs={12} lg={8}>
                        <div className="justify-content-center d-flex">
                            {people.map((person, index) => (
                                <div className="" key={index}>
                                    <ProfileSummary person={person} />
                                </div>
                            ))}
                        </div>
                    </Col>
                </Row> */}
                <Row>
                    <Col>
                        <p className="text-center mt-5"><LinkContainer to="team"><Button variant="primary">Meet the team</Button></LinkContainer></p>
                    </Col>
                </Row> 
            </Container>
        </div>
    );
}