import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Header.css';

export default function Header(props) {
    return (
        <div className="page-header">
            <Container>
                <Row>
                    <Col xs={12} md={8}>
                        <h6>{props.section}</h6>
                        <h1>{props.title}</h1>
                        {props.subheading ? <h2>{props.subheading}</h2> : null }
                    </Col>
                </Row>

                {props.primaryCTA ? <>{ props.primaryCTA }</>  : null}
                {props.secondaryCTA ? <>{props.secondaryCTA}</> : null}
            </Container>
        </div>
    )
}